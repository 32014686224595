@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #1a1a1a;
  --secondary-color: #00aeff;
  --secondary-hover: #008ccc;
  --black-color: #ffffff; /* Changed to white for better contrast */
  --lightGray-color: #6b7688;
  --gray-color: #ebebeb;
  --darkGray-color: #2c2c2c;
  --darkGray-color-text: #e0e0e0;
  --brown-color: #a89f9f; /* Changed to a lighter shade for better contrast */
  --white-color: #030303; /* Changed to black for better contrast */
  --nav-bar: rgba(44, 44, 44, 0.75);
  --white-box-shadow: rgba(255, 255, 255, 0.2);
  --blue-box-shadow: rgba(2, 175, 255, 1);
  --header-background-image: url(assets/bgIMG-dark.png);
  --dark-mode-image: url(assets/night-slider.png);
  --sun-slider-image: url(assets/sun-slider.png);
  --moon-slider-image: url(assets/moon-slider.png);
} 

:root.dark-mode{
  --primary-color: #edf2f8;
  --secondary-color: #00aeff;
  --secondary-hover: #008ccc;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --darkGray-color:rgb(51, 51, 51)8;
  --darkGray-color-text:rgb(51, 51, 51)8;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --nav-bar: rgba(255, 255, 255, 0.25);
  --white-box-shadow: rgba(0,0,0,0.1);
  --blue-box-shadow: rgba(2, 175, 255, 0.3);
  --header-background-image: url(assets/bgIMG.png);
  --dark-mode-image: url(assets/day-slider.png);
  --sun-slider-image: url(assets/sun-slider.png);
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}