.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;
  box-shadow: 0 0 20px var(--white-box-shadow);
  border-radius: 15px;

  img {
    width: 100%;
    height: 170px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    object-fit: cover;
  }

  h2{
    padding: 0px 12px 0px 12px;
  }

  p{
    padding: 0px 12px 12px 12px;
  }

  @media screen and (min-wdith: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
        height: 320px;
    }
  }
}